import React, { Suspense,useEffect } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { allRoute } from './util/routes';
import "react-toastify/dist/ReactToastify.css";
import {
  WixProvider,
  OAuthStrategy,
  AppStrategy,
  useWixModules,
} from "@wix/sdk-react";

import $ from 'jquery';  // Import jQuery
window.jQuery = $;  // Set jQuery globally


function App() {
  return (
      <Suspense fallback={<div></div>}>
        <Routes>
          {allRoute.map((routeData, index) => (
            <Route
             key={index}
             exact={routeData.exact}
             path={routeData.path}
             element={
              <routeData.layout>
                <routeData.component type={routeData?.type} tp_only={routeData?.tp_only} />
              </routeData.layout>
              }
           />
          ))}
        </Routes>
      </Suspense>
  );
}

export default App;
