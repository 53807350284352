import { React, useEffect,useState, useRef } from 'react';
import { Link,useLocation } from "react-router-dom";
import { debounce } from 'lodash';
const GetImage = process.env.REACT_APP_IMAGE_BASE_URL;
const Header = () => {
    const { pathname } = useLocation();
    const resizeHandlerRef = useRef();
    const [isToggledHeader, setIsToggledHeader] = useState(window.innerWidth > 991);
    const [isMobileHeader, setIsMobileHeader] = useState(window.innerWidth <= 991);
    const [isToggleLoginUsrHeader, setisToggleLoginUsrHeader] = useState(false);
    const [isFixed, setIsFixed] = useState(false);


    const handleHeaderToggle = () => {
        setIsToggledHeader(!isToggledHeader);
        if (!isToggledHeader) {
          document.body.classList.add('overflow_hiddenn');
        } else {
          document.body.classList.remove('overflow_hiddenn');
        }
    };

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 200) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      };

    const handleLoginUsrToggle = () => {
        setisToggleLoginUsrHeader(!isToggleLoginUsrHeader);
    };

    const handleResizeHeader = debounce(() => {
        setIsToggledHeader(window.innerWidth > 991);
    }, 200);

    resizeHandlerRef.current = handleResizeHeader;

  useEffect(() => {
    const resizeHandler = () => resizeHandlerRef.current();
    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);


    useEffect(() =>{
        window.scrollTo(0, 0);
        setIsToggledHeader(window.innerWidth > 991);
        document.body.classList.remove('overflow_hiddenn');
    }, [pathname]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <header className={isFixed ? "main_header fixed_header" : "main_header"} role="banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="header_static">
                                <div className="logo_section">
                                    <Link to={"/"}>
                                        <img src={`${GetImage}logo.png`} alt="Flexo - Logo" loading="lazy" />
                                    </Link>
                                </div>
                                <div className="main_menu_inner">
                                    <label id="menu_open_btn" className={`menu_open_btn ${isToggledHeader ? 'menu_open_btn close' : 'menu_open_btn'}`} onClick={handleHeaderToggle}>
                                        <span className="main-menu-btn-icon"><i class="fa-solid fa-bars"></i></span>
                                    </label>
                                    <div className="main_menu">
                                        {isToggledHeader && (
                                            <nav id="man_menu_show_hide">
                                                <ul>
                                                    <li>
                                                        <a href="#home">Home</a>
                                                    </li>
                                                    <li>
                                                        <a href="#Extension">Extension</a>
                                                    </li>
                                                    <li>
                                                        <a href="#Cases">Use Cases</a>
                                                    </li>
                                                    <li>
                                                        <a href="#Advertisers">Advertisers</a>
                                                    </li>
                                                    <li>
                                                        <a href="#partner">Partners</a>
                                                    </li>                                            
                                                    <li>
                                                        <a href="#Features">Features</a>
                                                    </li>
                                                    <li>
                                                        <a href="#Roadmap">Roadmap</a>
                                                    </li>
                                                    {/* <li>
                                                        <a href="#team">Team</a>
                                                    </li>                                                       */}
                                                    {/* <li className='login_dropdown'>
                                                        <a href={void(0)} onClick={handleLoginUsrToggle}><img src={Profile} alt="user"/> User <small className="down_icon"></small></a>
                                                        {isToggledHeader && <ul className="sub_menu pages">
                                                            <li>
                                                                <a href={"/"} target='_blank'>My Account</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/"}>Logout</a>
                                                            </li>
                                                        </ul>}
                                                    </li> */}
                                                </ul>
                                            </nav>
                                        )}
                                        <div className="login_box">
                                            {/* <button type="button" className="login_popup_cs">Login</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;